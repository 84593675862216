<!-- eslint-disable vue/no-lone-template -->
<template>
  <div>
    <b-row v-if="isPdf">
      <b-col>
        <span
          class="w-100 d-flex justify-content-between align-items-center"
          :style="sectionHeaderTextColor"
        >
          <slot name="pdf-title-content">
            <component
              :is="pdfHeaderTagToUse"
              class="mb-0"
            >
              {{ title }}
            </component>
          </slot>
        </span>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <slot name="title-area-content">
          <div class="d-flex justify-content-between align-items-center settings-button-container">
            <component
              :is="headerTag"
              class="d-inline-block font-size-bigger font-weight-500 mb-0"
            >
              {{ title }}
            </component>
            <div
              class="d-flex justify-content-end align-items-center"
              :style="shouldHaveRightPadding ? 'padding-right: 45px' : ''"
            >
              <SettingsButton
                v-if="!isPdf && (allCols.length > 0 || $slots['settings-menu'])"
                :all-options="allCols"
                :selected-options.sync="displayedColsModel"
                class="ml-3"
                menu-class="scroll-lg shadow"
              >
                <template #header>
                  <slot name="dropdown-header" />
                </template>
                <template>
                  <slot name="settings-menu" />
                </template>
              </SettingsButton>
              <FactsheetTooltip
                v-if="!isPdf"
                :names="disclaimerText"
                :top-offset="topOffset"
                :right-offset="rightOffset"
              />
            </div>
          </div>
        </slot>
      </b-col>
    </b-row>
    <hr
      v-if="shouldShowHr"
      :class="hrClasses"
      :style="hrStyle"
    />
  </div>
</template>

<script lang="ts">
import { useIsPdf, usePdfPreferences } from '@/composables/usePdf';
import { computed, defineComponent, PropType } from 'vue';
import SettingsButton from '@/components/standard-components/StandardSettingsButton.vue';
import { useVModel } from '@vueuse/core';
import FactsheetTooltip from '@/whitelabel/hsbc/views/factsheets/FactsheetTooltip.vue';

export default defineComponent({
  name: 'SectionTitleHSBC',
  components: {
    FactsheetTooltip,
    SettingsButton,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    headerTag: {
      type: String,
      required: false,
      default: 'label',
    },
    pdfHeaderTag: {
      type: String,
      required: false,
      default: 'h4',
    },
    rightSideText: {
      type: String,
      required: false,
      default: '',
    },
    shouldShowHr: {
      type: Boolean,
      required: false,
      default: true,
    },
    allCols: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
    displayedCols: {
      type: Array,
      required: false,
      default: () => [],
    },
    includeMarginBottom: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * Indicate if right padding should be added for export graph button
     */
    shouldHaveRightPadding: {
      type: Boolean,
      required: false,
      default: false,
    },
    disclaimerText: {
      type: [String, Array] as PropType<string | string[]>,
      required: false,
      default: () => [],
    },
    topOffset: {
      type: String,
      required: false,
      default: '5px',
    },
    rightOffset: {
      type: String,
      required: false,
      default: '50px',
    },
  },
  setup(props, { emit }) {
    const displayedColsModel = useVModel(props, 'displayedCols', emit);

    const pdfHeaderTagToUse = computed(() => {
      if (props.pdfHeaderTag) return props.pdfHeaderTag;
      return props.headerTag;
    });

    /**
     * In the HSBC whitelabel component, the hr class should always be hr-accent
     */
    const hrClasses = computed(() => {
      const base = `mt-1 ${props.includeMarginBottom ? 'mb-4' : 'mb-0'}`;
      return `${base} hr-accent`;
    });

    const isPdf = useIsPdf();
    const { sectionDividerColor, sectionHeaderTextColor } = usePdfPreferences();

    const hrStyle = computed(() => {
      const base = {
        'border-width': '2px',
      };

      return base;
    });

    return {
      isPdf,
      sectionHeaderTextColor,
      sectionDividerColor,
      displayedColsModel,
      pdfHeaderTagToUse,
      hrClasses,
      hrStyle,
    };
  },
});
</script>
